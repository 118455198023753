import { SET_GET_A_QUOTE_MODAL } from '../actions/types';

const initialState = {
    isGetAQuoteShowing: false
};

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_GET_A_QUOTE_MODAL: 
            return {
                ...state,
                isGetAQuoteShowing: action.payload
            }
        default: 
            return state;
    }
}