import React, {lazy} from "react";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";

// Components
import Navbar from "../../components/navbar/navbar.component";

// Assets
import contactLogoAlt from "../../assets/icons/contactLogo-alt.svg";
import hamburgerIcon from "../../assets/icons/menu_icon.svg";
import logoAlt from "../../assets/webAssets/logo-alt.svg";
import closeIcon from "../../assets/icons/close_button.svg";

// Lazy Component Imports
const QuoteForm = lazy(() =>
    import("../form/quote_form/quoteForm.component.js")
);

const sideDrawerItems = [
    { title: "Home", link: "/" },
    {
        title: "House Insurance",
        link: "/house-insurance",
    },
    {
        title: "House Insurance Savings",
        link: "/house-insurance-savings",
    },
    {
        title: "Policies",
        link: "/house-insurance-policy-renewal",
    },
    {
        title: "About Us",
        link: "/about-us",
    },
    {
        title: "Customer Service",
        link: "/customer-care",
    },
    {
        title: "Blog",
        link: "/blog",
    },
];

const block3 = {
    button_text: "Get a Quote",
    button_style: "btn-quote",
    modalStyle: "modalLarge",
    form: <DgLazyComponent Component={QuoteForm} />,
};

export const NavbarBlock = (props) => (
    <Navbar
        hamburgerIcon={hamburgerIcon}
        logoAlt={logoAlt}
        phoneIcon={contactLogoAlt}
        closeIcon={closeIcon}
        button={block3}
        sideDrawerItems={sideDrawerItems}
    />
);
