import {
    QUOTE_SUBMIT,
    GET_QUOTES,
    SET_QUOTE_INITIAL_VALUES,
    ADDRESS_LOOKUP_FAILED,
    ADDRESS_SELECT_FAILED,
    ALTERNATE_ADDRESS_LOOKUP_FAILED,
    ADDRESS_LOOKUP_SUCCESS,
    ADDRESS_SELECT_SUCCESS,
    ALTERNATE_ADDRESS_LOOKUP_SUCCESS,
    SET_CURRENT_ALTERNATE_ADDRESS,
    SET_QUOTE_PAGE,
    SET_CURRENT_ADDRESS
} from "../actions/types";

const initialQuoteForm = {
    your_details: {
        title: "Mr"
    },
    your_house: {
        alarm_type: "none",
        two_or_more_smoke_alarms: "no",
        property_type: "Bungalow",
        cover_type: "House Insurance",
        heating_type: "Electric"
    },
    claims: {
        claim_in_last_5_years: "no",
    },
    your_insurance_details: {
        first_time_buyer: "no",
        currently_or_previously_insured: "no",
        previous_claims: "no"
    }
};

const initialState = {
    quotes: [],
    currentQuote: {},
    addresses: [],
    alternateAddresses: [],
    quoteInitial: initialQuoteForm,
    message: "",
    ok: true,
    error: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_QUOTE_PAGE:
            return {
                ...state,
                currentQuote: action.payload,
            };
        case GET_QUOTES:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                quotes: action.payload.quotes
            };
        case QUOTE_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentQuote: action.payload.quote
            };
        case SET_QUOTE_INITIAL_VALUES:
            return {
                ...state,
                quoteInitial: action.payload
            };
        case ADDRESS_LOOKUP_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                addresses: action.payload.addresses,
                message: action.payload.message
            };
        case ADDRESS_SELECT_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message
            };
        case ADDRESS_LOOKUP_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case ADDRESS_SELECT_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case ALTERNATE_ADDRESS_LOOKUP_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                alternateAddresses: action.payload.addresses,
                message: action.payload.message
            };
        case ALTERNATE_ADDRESS_LOOKUP_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case SET_CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
                addresses: []
            };
        case SET_CURRENT_ALTERNATE_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
                alternateAddresses: []
            };
        default:
            return state;
    }
}
