import React, { Component, lazy } from "react";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

// Actions
import { setIsMobileMenuOpen } from "../../actions/utility.action";

// Style
import "../../styles/components/navbar/navbarMobile.scss";

// Assets
import logo from "../../assets/icons/imh-logo.png";
import menuIcon from "../../assets/icons/menu_icon.svg";

// Dynamic component imports
const DgPicture = lazy(() => import("dg-utils/build/DgPicture"));

class NavbarMobile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showIcon: false,
        };

        this.openClose = this.openClose.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenToScroll);
    }

    listenToScroll() {
        const navbarOffset = 75;
        const currentScroll = window.pageYOffset;
        const showIcon = currentScroll >= navbarOffset;

        if (this.state.showIcon !== showIcon) {
            this.setState({ showIcon: showIcon });
        }
    }

    openClose() {
        this.props.setIsMobileMenuOpen(!this.props.utility.isMobileMenuOpen);
    }

    render() {
        return (
            <div
                className={
                    this.props.utility.isMobileMenuOpen
                        ? `navbar-header hide`
                        : `navbar-header`
                }
            >
                <div className="logo">
                    <NavLink to="/" className="nav-link">
                        <img
                            src={logo}
                            alt={`${process.env.REACT_APP_PROJECT_NAME} Logo`}
                        />
                    </NavLink>
                </div>

                <div className="hamburger-button">
                    <button
                        type="button"
                        className="nav-link"
                        onClick={() => this.openClose()}
                    >
                        <DgLazyComponent Component={DgPicture}>
                            <img src={menuIcon} alt={`Hamburger icon`} />
                         </DgLazyComponent>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    utility: state.utilities,
});

const mapDispatchToProps = (dispatch) => ({
    setIsMobileMenuOpen: (openState) => dispatch(setIsMobileMenuOpen(openState)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NavbarMobile);
