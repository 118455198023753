import { 
    RENEWAL_SUBMIT, 
    GET_RENEWALS,
    SET_IS_LOADING_RENEWAL,
    PAYMENT_CREATE_RENEWAL,
    ADDRESS_LOOKUP_FAILED,
    ADDRESS_LOOKUP_SUCCESS,
    SET_CURRENT_ADDRESS
} from "../actions/types";

const initialState = {
    renewals: [],
    addresses: [],
    currentRenewal: {},
    message: "",
    ok: true,
    errors: [],
    isRenewalLoading: false,
    isLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_IS_LOADING_RENEWAL:
            return {
                ...state,
                isLoading: action.payload
            }
        case PAYMENT_CREATE_RENEWAL:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                renewals: action.payload.renewals
            };
        case GET_RENEWALS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                renewals: action.payload.renewals
            };
        case RENEWAL_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentRenewal: action.payload.renewal,
                isRenewalLoading: false
            };
        case ADDRESS_LOOKUP_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                addresses: action.payload.addresses,
                message: action.payload.message
            };
        case ADDRESS_LOOKUP_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case SET_CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
                addresses: []
            };
        default:
            return state;
    }
}
