export { reducer as form } from "redux-form";
export { default as modals } from "./modals.reducer";
export { default as quotes } from "./quote.reducer";
export { default as utilities } from "./utility.reducer";
export { default as callBacks } from "./callBack.reducer";
export { default as amends } from "./amend.reducer";
export { default as reviews } from "./reviews.reducer";
export { default as renewals } from "./renewal.reducer";
export { default as emails } from "./email.reducer";
export { default as careers } from "./career.reducer";
export { default as users} from 'dg-admin/build/userReducer.js' // import the contactReducer from dg-admin
export { default as contacts} from 'dg-admin/build/contactReducer.js' // import the contactReducer from dg-admin
export { default as blogs} from 'redux-blogs/build/reducer.js' // import the blogs reducer from redux-blogs
export { default as basketballIrelandQuote } from './basketballIrelandQuote';
//export { default as cms} from 'dg-cms/build/reducer.js' // import the cms reducer from dg-cms
//export { loginReducer as loggedInUser } from 'dg-admin' // import the loginReducer from dg-admin
