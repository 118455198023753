import React, { Component } from "react";

//Components

//Styles
import "../../styles/components/navbar/navbar.scss";
import "../../styles/components/navbar/sideDrawer.scss";

import { default as Navbar2 } from './navbar';
import NavbarMobile from './navbarMobile';

class Nav extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar2 />
                <NavbarMobile />
            </React.Fragment>
        );
    }
}
export default Nav;
