import {
    CREATE_BASKETBALLIRELANDQUOTE,
    UPDATE_BASKETBALLIRELANDQUOTE,
    DELETE_BASKETBALLIRELANDQUOTE,
    GET_BASKETBALLIRELANDQUOTE,
    GET_ALL_BASKETBALLIRELANDQUOTE,
    SET_CURRENT_BASKETBALLIRELANDQUOTE,
} from "../actions/types";

const initialState = {
    currentBasketballIrelandQuote: {},
    basketballIrelandQuotes: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_BASKETBALLIRELANDQUOTE:
        case CREATE_BASKETBALLIRELANDQUOTE:
        case UPDATE_BASKETBALLIRELANDQUOTE:
        case GET_BASKETBALLIRELANDQUOTE:
            return {
                ...state,
                currentBasketballIrelandQuote: action.payload,
            };
        case DELETE_BASKETBALLIRELANDQUOTE:
            return {
                ...state,
                currentBasketballIrelandQuote: {},
            };
        case GET_ALL_BASKETBALLIRELANDQUOTE:
            return {
                ...state,
                basketballIrelandQuotes: action.payload,
            };
        default:
            return state;
    }
}
