export const routes = [
    {
        url: "/admin/user",
        text: "Users",
    },
    {
        url: "/admin/posts",
        text: "Blogs",
    },
    {
        url: "/admin/quotes",
        text: "Quotes",
    },
    {
        url: "/admin/callbacks",
        text: "Callbacks",
    },
    {
        url: "/admin/renewals",
        text: "Renewals",
    },
    {
        url: "/admin/amendments",
        text: "Amendments",
    },
    {
        url: "/admin/contacts",
        text: "Contacts",
    },
    {
        url: "/admin/careers",
        text: "Job Applications",
    },
    {
        url: "/admin/cms",
        text: "CMS",
    },
];
