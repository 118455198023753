import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

//import ProtectedRoute from "./components/protectedRoute.component";
import history from "./history";
import ScrollToTop from "./scrollToTop";
import Preloader from "./components/preloader/preloader";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";

// Asset files
import { routes as adminRoutes } from "./assets/files/adminRoutes";

// Import from packages
const Sidebar = lazy(() => import("dg-admin/build/Sidebar"));
const ProtectedRoute = lazy(() => import("dg-admin/build/ProtectedRoute"));

// Import Page Components
const Home = lazy(() => import("./components/home/home.component.js"));
const HouseInsurance = lazy(() =>
    import("./components/house_insurance/houseInsurance.component.js")
);
const HouseInsuranceSavings = lazy(() =>
    import(
        "./components/house_insurance_savings/houseInsuranceSavings.component.js"
    )
);

const Policies = lazy(() =>
    import("./components/policies/policies.component.js")
);

const WhyChooseUs = lazy(() => import("./components/why_us/whyChooseUs.js"));

const About = lazy(() => import("./components/about/about.component.js"));

const ContactFAQ = lazy(() =>
    import("./components/contact_faq/contactFAQ.component.js")
);
const HolidayHome = lazy(() =>
    import("./components/holiday_home/holidayHome.component.js")
);
const Airbnb = lazy(() => import("./components/airbnb/airbnb.component.js"));
const Landlord = lazy(() =>
    import("./components/landlord/landlord.component.js")
);
const Contents = lazy(() =>
    import("./components/contents/contents.component.js")
);
const Homeowners = lazy(() =>
    import("./components/homeowners/homeowners.component.js")
);
const OtherServices = lazy(() =>
    import(
        "./components/other_insurance_services/otherInsuranceServices.component.js"
    )
);
const Blog = lazy(() => import("./components/blog/blog.component.js"));
const Post = lazy(() => import("./components/blog/post.component"));
const Login = lazy(() => import("./components/login/login.component"));
const PasswordReset = lazy(() =>
    import("./components/login/verifyPasswordReset.component")
);
const Disclaimer = lazy(() =>
    import("./components/legal/disclaimer.component")
);
const Cookie = lazy(() => import("./components/legal/cookie.component"));
const Assumptions = lazy(() =>
    import("./components/legal/assumptions.component")
);
const PaymentSuccess = lazy(() =>
    import("./components/payments/success.payment")
);
const PaymentFailed = lazy(() =>
    import("./components/payments/failed.payment")
);
const Careers = lazy(() => import("./components/careers/careers"));
// Basketball Ireland Landing Page
const BasketballIreland = lazy(() =>
    import("./pages/basketballIreland/basketballIreland.js")
);

// Protected Routes
// Admin - Contacts
const ContactList = lazy(() =>
    import("./components/admin/contact/list.component")
);
const ViewContact = lazy(() =>
    import("./components/admin/contact/view.component")
);

// Admin - Amends
const AmendList = lazy(() => import("./components/admin/amend/list.component"));
const ViewAmend = lazy(() => import("./components/admin/amend/view.component"));

// Admin - CMS
const CMSList = lazy(() => import("./components/admin/cms/cmsList"));
const EditCMS = lazy(() => import("./components/admin/cms/editCMS"));

// Admin - Callbacks
const CallBackList = lazy(() =>
    import("./components/admin/callBack/list.component")
);
const ViewCallBack = lazy(() =>
    import("./components/admin/callBack/view.component")
);

// Admin - Users
const UserList = lazy(() =>
    import("./components/admin/users/adminUserList.component")
);
const EditUser = lazy(() =>
    import("./components/admin/users/update.component")
);
const CreateUser = lazy(() =>
    import("./components/admin/users/create.component")
);

// Admin - Renewals
const RenewalList = lazy(() =>
    import("./components/admin/renewal/list.component")
);
const ViewRenewal = lazy(() =>
    import("./components/admin/renewal/view.component")
);

// Admin -Quotes
const QuoteList = lazy(() => import("./components/admin/quote/list.component"));
const ViewQuote = lazy(() => import("./components/admin/quote/view.component"));

// Admin - Emails
const CustomEmail = lazy(() =>
    import("./components/admin/emails/custom/customEmail.component")
);
const TemplateEmail1 = lazy(() =>
    import("./components/admin/emails/template_1/templateEmail.component")
);
const TemplateEmail2 = lazy(() =>
    import("./components/admin/emails/template_2/templateEmail.component")
);
const TemplateEmail3 = lazy(() =>
    import("./components/admin/emails/template_3/templateEmail.component")
);
const TemplateEmail4 = lazy(() =>
    import("./components/admin/emails/template_4/templateEmail.component")
);

// Admin - Careers
const CareerList = lazy(() =>
    import("./components/admin/career/list.component")
);

// Admin - Blogs
const AdminPostListPage = lazy(() =>
    import("redux-blogs/build/AdminPostListPage")
);
const AdminEditPostPage = lazy(() =>
    import("redux-blogs/build/AdminEditPostPage")
);
const AdminNewPostPage = lazy(() =>
    import("redux-blogs/build/AdminNewPostPage")
);

function Router() {
    return (
        <Suspense fallback={<Preloader />}>
            <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/house-insurance" element={<HouseInsurance />} />
                <Route
                    path="/house-insurance-savings"
                    element={<HouseInsuranceSavings />}
                />

                <Route
                    path="/house-insurance-policy-renewal"
                    element={<Policies />}
                />

                <Route path="/why-choose-us" element={<WhyChooseUs />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/cookies-policy" element={<Cookie />} />
                <Route path="/assumptions" element={<Assumptions />} />
                <Route path="/customer-care" element={<ContactFAQ />} />
                <Route
                    path="/renewal/success"
                    exact
                    element={<PaymentSuccess />}
                />
                <Route
                    path="/renewal/failed"
                    exact
                    element={<PaymentFailed />}
                />
                <Route path="/blog" exact element={<Blog />} />
                <Route path="/blog/post/:id" exact element={<Post />} />
                <Route path="/blog/post/philip" exact element={<Post />} />
                <Route
                    path="/holiday-home-insurance"
                    element={<HolidayHome />}
                />
                <Route path="/airbnb-insurance" element={<Airbnb />} />
                <Route path="/landlord-insurance" element={<Landlord />} />
                <Route
                    path="/tenant-contents-insurance"
                    element={<Contents />}
                />
                <Route
                    path="/other-insurance-services"
                    element={<OtherServices />}
                />
                <Route path="/careers" element={<Careers />} />
                <Route path="/homeowners-insurance" element={<Homeowners />} />
                <Route
                    path="/basketball-ireland-exclusive-offers"
                    exact
                    element={<BasketballIreland />}
                />

                <Route path="/admin" exact element={<Login />} />
                <Route
                    path="/verifypasswordreset"
                    exact
                    element={<PasswordReset />}
                />
                <Route
                    path="/admin/user"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={UserList}
                        />
                    }
                />
                <Route
                    path="/admin/user/create"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={CreateUser}
                        />
                    }
                />
                <Route
                    path="/admin/user/edit/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={EditUser}
                        />
                    }
                />
                <Route
                    path="/admin/contacts"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={ContactList}
                        />
                    }
                />
                <Route
                    path="/admin/contact/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={ViewContact}
                        />
                    }
                />
                <Route
                    path="/admin/cms"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={CMSList}
                        />
                    }
                />
                <Route
                    path="/admin/cms/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={EditCMS}
                        />
                    }
                />
                <Route
                    path="/admin/amendments"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AmendList}
                        />
                    }
                />
                <Route
                    path="/admin/amend/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={ViewAmend}
                        />
                    }
                />
                <Route
                    path="/admin/callbacks"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={CallBackList}
                        />
                    }
                />
                <Route
                    path="/admin/callback/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={ViewCallBack}
                        />
                    }
                />
                <Route
                    path="/admin/renewals"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={RenewalList}
                        />
                    }
                />
                <Route
                    path="/admin/renewal/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={ViewRenewal}
                        />
                    }
                />
                <Route
                    path="/admin/quotes"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={QuoteList}
                        />
                    }
                />
                <Route
                    path="/admin/quote/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={ViewQuote}
                        />
                    }
                />
                <Route
                    path="/admin/posts"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={(routeProps) => (
                                <AdminPostListPage
                                    {...routeProps}
                                    history={history}
                                    sidebar={
                                        <DgLazyComponent
                                            Component={Sidebar}
                                            routes={adminRoutes}
                                        />
                                    }
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/admin/posts/create"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={(routeProps) => (
                                <AdminNewPostPage
                                    {...routeProps}
                                    history={history}
                                    sidebar={
                                        <DgLazyComponent
                                            Component={Sidebar}
                                            routes={adminRoutes}
                                        />
                                    }
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/admin/posts/edit/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={(routeProps) => (
                                <AdminEditPostPage
                                    {...routeProps}
                                    history={history}
                                    sidebar={
                                        <DgLazyComponent
                                            Component={Sidebar}
                                            routes={adminRoutes}
                                        />
                                    }
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/admin/careers"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={CareerList}
                        />
                    }
                />
                <Route
                    path="/admin/email/custom/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={CustomEmail}
                        />
                    }
                />
                <Route
                    path="/admin/email/template-1/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={TemplateEmail1}
                        />
                    }
                />
                <Route
                    path="/admin/email/template-2/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={TemplateEmail2}
                        />
                    }
                />
                <Route
                    path="/admin/email/template-3/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={TemplateEmail3}
                        />
                    }
                />
                <Route
                    path="/admin/email/template-4/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={TemplateEmail4}
                        />
                    }
                />
                <Route path="*" element={<HomeRedirect />} />
            </Routes>
        </Suspense>
    );
}

function HomeRedirect() {
    const navigate = useNavigate();
    useEffect(() => navigate("/"), []);
    return <div></div>;
}

export default Router;
