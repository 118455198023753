export const FETCH_POSTS = 'FETCH_POSTS';
export const NEW_POST = 'NEW_POST';
export const SELECT_POST = 'SELECT_POST';
export const SET_FILTER = 'SET_FILTER';
export const CLEAR_POST_FORM = 'CLEAR_POST_FORM';
export const SET_IS_MOBILE_MENU_OPEN = 'SET_IS_MOBILE_MENU_OPEN';

// Users
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USERS = 'GET_USERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_GET_A_QUOTE_MODAL = 'SET_GET_A_QUOTE_MODAL';
export const NEW_QUOTE = 'NEW_QUOTE';
export const GET_QUOTES = 'GET_QUOTES';
export const QUOTE_SUBMIT = 'QUOTE_SUBMIT';
export const SET_QUOTE_INITIAL_VALUES = 'SET_QUOTE_INITIAL_VALUES';
export const SELECT_QUOTE = 'SELECT_QUOTE';
export const FETCH_QUOTES = 'FETCH_QUOTES';
export const SET_IS_QUOTE_LOADING = 'SET_IS_QUOTE_LOADING';
export const QUOTE_SUBMIT_FAILED = 'QUOTE_SUBMIT_FAILED';
export const QUOTE_SUBMIT_SUCCESS = 'QUOTE_SUBMIT_SUCCESS';
export const SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS';
export const SET_CURRENT_ALTERNATE_ADDRESS = 'SET_CURRENT_ALTERNATE_ADDRESS';
export const ADDRESS_LOOKUP_FAILED = 'ADDRESS_LOOKUP_FAILED';
export const ADDRESS_SELECT_FAILED = 'ADDRESS_SELECT_FAILED';
export const ALTERNATE_ADDRESS_LOOKUP_FAILED = 'ALTERNATE_ADDRESS_LOOKUP_FAILED';
export const ADDRESS_LOOKUP_SUCCESS = 'ADDRESS_LOOKUP_SUCCESS';
export const ADDRESS_SELECT_SUCCESS = 'ADDRESS_SELECT_SUCCESS';
export const ALTERNATE_ADDRESS_LOOKUP_SUCCESS = 'ALTERNATE_ADDRESS_LOOKUP_SUCCESS';
export const SET_QUOTE_PAGE = 'SET_QUOTE_PAGE';
export const SET_PREVIOUS_QUOTE_PAGE = 'SET_PREVIOUS_QUOTE_PAGE';

export const EMAIL_SUBMIT = 'EMAIL_SUBMIT';

export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const GET_POST = 'GET_POST';

export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILED = 'GET_REVIEWS_FAILED';

export const SET_UPLOADS = 'SET_UPLOADS';

export const CALLBACK_SUBMIT = 'RENEWAL_SUBMIT';
export const GET_CALLBACKS = 'GET_RENEWALS';

export const RENEWAL_SUBMIT = 'RENEWAL_SUBMIT';
export const GET_RENEWALS = 'GET_RENEWALS';
export const PAYMENT_CREATE_RENEWAL = 'PAYMENT_CREATE_RENEWAL';
export const SET_IS_LOADING_RENEWAL = 'SET_IS_LOADING_RENEWAL';

export const AMEND_SUBMIT = 'AMEND_SUBMIT';
export const GET_AMENDS = 'GET_AMENDS';

export const CONTACT_SUBMIT = 'CONTACT_SUBMIT';
export const GET_CONTACTS = 'GET_CONTACTS';
export const SET_CONTACT_INITIAL_VALUES = 'SET_CONTACT_INITIAL_VALUES';

export const SET_GET_A_QUOTE_INITIAL_VALUES = 'SET_GET_A_QUOTE_INITIAL_VALUES';
export const FETCH_AMENDS = 'FETCH_AMENDS';
export const AMEND_SUBMIT_SUCCESS = 'AMEND_SUBMIT_SUCCESS';
export const AMEND_SUBMIT_FAILED = 'AMEND_SUBMIT_FAILED';
export const SET_IS_AMEND_LOADING = 'SET_IS_AMEND_LOADING';
export const FETCH_RENEWS = 'FETCH_RENEWS';
export const RENEW_SUBMIT_SUCCESS = 'RENEW_SUBMIT_SUCCESS';
export const RENEW_SUBMIT_FAILED = 'RENEW_SUBMIT_FAILED';
export const SET_IS_RENEW_LOADING = 'SET_IS_RENEW_LOADING';
export const SET_SIDE_DRAWER_OPEN = 'SIDE_DRAWER_OPEN';

export const CREATE_JOB_APPLICATION = 'CREATE_JOB_APPLICATION';
export const SET_CURRENT_APPLICATION = 'SET_CURRENT_APPLICATION';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';

// Basketall Ireland Quotes
export const CREATE_BASKETBALLIRELANDQUOTE = "CREATE_BASKETBALLIRELANDQUOTE";
export const UPDATE_BASKETBALLIRELANDQUOTE = "UPDATE_BASKETBALLIRELANDQUOTE";
export const DUPLICATE_BASKETBALLIRELANDQUOTE = "DUPLICATE_BASKETBALLIRELANDQUOTE";
export const DELETE_BASKETBALLIRELANDQUOTE = "DELETE_BASKETBALLIRELANDQUOTE";
export const GET_BASKETBALLIRELANDQUOTE = "GET_BASKETBALLIRELANDQUOTE";
export const GET_ALL_BASKETBALLIRELANDQUOTE = "GET_ALL_BASKETBALLIRELANDQUOTE";
export const SET_CURRENT_BASKETBALLIRELANDQUOTE = "SET_CURRENT_BASKETBALLIRELANDQUOTE";
