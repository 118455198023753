import { GET_REVIEWS_FAILED, GET_REVIEWS_SUCCESS } from "../actions/types";

const initialState = {
    reviews: [],
    message: "",
    ok: true,
    error: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REVIEWS_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                reviews: action.payload.reviews
            };
        case GET_REVIEWS_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        default:
            return state;
    }
}
