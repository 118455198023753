import { CALLBACK_SUBMIT, GET_CALLBACKS } from "../actions/types";

const initialState = {
    callbacks: [],
    currentCallback: {},
    message: "",
    ok: true,
    errors: [],
    isCallbackLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CALLBACKS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                callbacks: action.payload.callbacks
            };
        case CALLBACK_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentCallback: action.payload.callback,
                isCallbackLoading: false
            };
        default:
            return state;
    }
}
