import { AMEND_SUBMIT, GET_AMENDS } from "../actions/types";

const initialState = {
    amends: [],
    currentAmend: {},
    message: "",
    ok: true,
    errors: [],
    isAmendLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_AMENDS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                amends: action.payload.amends
            };
        case AMEND_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentAmend: action.payload.amend,
                isAmendLoading: false
            };
        default:
            return state;
    }
}
