import { 
    CREATE_JOB_APPLICATION,
    SET_CURRENT_APPLICATION,
    GET_APPLICATIONS
} from "../actions/types";

const initialState = {
    applications: [],
    currentApplication: {},
    message: "",
    ok: false,
    errors: [],
    isLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_APPLICATIONS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                applications: action.payload.applications
            };
        case CREATE_JOB_APPLICATION:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
            };
        case SET_CURRENT_APPLICATION:
            return {
                ...state,
                currentApplication: action.payload
            };
        default:
            return state;
    }
}
