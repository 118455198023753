import React, { Component, lazy } from "react";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";

// Styles
import "../../styles/components/payments/paymentPage.scss";

//Assets

// Components
import { NavbarBlock } from "../header/navbar.component";
import BlockSpacer from "../spacer/blockSpacer.component";
import Loading from "../loading/loading";

// Dynamic component imports
const Footer = lazy(() => import("../footer/footer.component"));

class Preloader extends Component {
    render() {
        return (
            <div className="payment-page-container">
                <NavbarBlock />
                <BlockSpacer />
                <div className="payment-message-container">
                    <Loading />
                </div>
                <BlockSpacer />
                <DgLazyComponent Component={Footer} />
            </div>
        );
    }
}
export default Preloader;
