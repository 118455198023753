import React, { Component } from "react";

// Styles
import "../../styles/components/spacer/spacer.scss";

class BlockSpacer extends Component {
    render() {
        return <div className="block-spacer" />;
    }
}

export default BlockSpacer;
