import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import DgLazyComponent from "dg-utils/build/DgLazyComponent"; 

// Styles
import "../../styles/components/navbar/navbar2.scss";

// Actions
import { setIsMobileMenuOpen } from "../../actions/utility.action";

// Components

// Assets
import logo from "../../assets/webAssets/imh-white.svg";
import close from "../../assets/icons/close_button.svg";
import phoneIcon from "../../assets/icons/contactLogo-alt.svg";

// Dynamic component imports
const DgPicture = lazy(() => import("dg-utils/build/DgPicture"));
const ModalBtn = lazy(() => import("../modals/blockModal.component"));

class Navbar3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showServicesMenu: false,
            showIcon: false,
        };

        this.openClose = this.openClose.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenToScroll);
    }

    listenToScroll() {
        const navbarOffset = 75;
        const currentScroll = window.pageYOffset;
        const isMobile = window.innerWidth < 1025;
        const showIcon = currentScroll >= navbarOffset && !isMobile;

        if (this.state.showIcon !== showIcon) {
            this.setState({ showIcon: showIcon });
        }
    }

    openClose() {
        this.props.setIsMobileMenuOpen(!this.props.utility.isMobileMenuOpen);
    }

    navigateTo() {
        window.scrollTo(0, 0);
        this.props.setIsMobileMenuOpen(false);
    }

    render() {
        return (
            <div
                className={`navbar ${
                    this.props.utility?.isMobileMenuOpen ? "open" : ""
                }`}
            >
                <div className="navbar-main">
                    <div className={`navbar-logo-container`}>
                        <div className="logo">
                            <NavLink
                                to="/"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <img
                                    src={logo}
                                    alt={`${process.env.REACT_APP_PROJECT_NAME} Logo`}
                                />
                            </NavLink>
                        </div>
                        <div className="close-container">
                            <button
                                type="button"
                                className="close-button"
                                onClick={() => this.openClose()}
                            >
                                <img src={close} alt="Close icon" />
                            </button>
                        </div>
                    </div>
                    <div className="navbar-heading">
                        <h1 className="heading-primary">Insure My House</h1>
                    </div>
                    <ul className="navbar-list">
                        <li className="nav-item">
                            <NavLink
                                to="/house-insurance"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">
                                    House Insurance
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/house-insurance-savings"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">
                                    House Insurance Savings
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/why-choose-us"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">Why Choose Us</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/house-insurance-policy-renewal"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">Policies</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/about-us"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">About Us</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/customer-care"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">
                                    Customer Service
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/careers"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">Careers</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/blog"
                                className="nav-link"
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <span className="link-text">Blogs</span>
                            </NavLink>
                        </li>
                        <li className="nav-item phone">
                            <a href={`tel:01-6032999`} className="nav-link">
                                <DgLazyComponent
                                    Component={DgPicture}
                                    className="nav-icon"
                                >
                                    <img
                                        src={phoneIcon}
                                        alt="call us"
                                        loading="lazy"
                                    />
                                </DgLazyComponent>
                                <span className="link-text">
                                    Call Us: 01-6032999
                                </span>
                            </a>
                        </li>
                        <li className="nav-item get-a-quote">
                            <DgLazyComponent
                                Component={ModalBtn}
                                type={"btn-quote"}
                                text={"Get a Quote"}
                                modalStyle={"modalLarge"}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    utility: state.utilities,
});

export default connect(mapStateToProps, {
    setIsMobileMenuOpen,
})(Navbar3);
