import { EMAIL_SUBMIT } from "../actions/types";

const initialState = {
    emails: [],
    currentEmail: {},
    message: "",
    ok: true,
    error: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case EMAIL_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentEmail: action.payload.email
            };
        default:
            return state;
    }
}
