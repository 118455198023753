import { 
    SET_SIDE_DRAWER_OPEN,
    SET_IS_MOBILE_MENU_OPEN
} from "../actions/types";

const initialState = {
    isMobileMenuOpen: false,
    sideDrawerOpen: false,
    ok: true,
    message: "",
    errors: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SIDE_DRAWER_OPEN:
            return {
                ...state,
                sideDrawerOpen: action.payload
            };
        case SET_IS_MOBILE_MENU_OPEN: 
            return {
                ...state,
                isMobileMenuOpen: action.payload
            }
        default:
            return state;
    }
}
