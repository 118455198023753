import { 
    SET_SIDE_DRAWER_OPEN,
    SET_IS_MOBILE_MENU_OPEN
} from "./types";

export const setSideDrawerOpen = (sideDrawerOpen) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_SIDE_DRAWER_OPEN,
            payload: sideDrawerOpen
        });

        resolve(sideDrawerOpen);
    });
};

export const setIsMobileMenuOpen = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_MOBILE_MENU_OPEN,
            payload: data
        });

        resolve(data);
    });
}
